import "./Header.scss";
import { Component } from "react";
import React from "react";
import { InlineWidget } from "react-calendly";
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

//widget toggle
function Widget() {
  return (
    <div id="widget" >
    <InlineWidget url="https://calendly.com/dealigense/demo" />
    </div>
  );
}
function WidgetToggle(){
  const [widgetVisible, setWidgetVisible] = useState(false);

  const WidgetToggle = () => {
    setWidgetVisible(!widgetVisible);
  };
  
  let menuRef = useRef();
//this function allows the widget to disappear when the user clicks out of it
  useEffect(()=> {
    let handler = (e)=>{
      if(e.target){
        if (!menuRef.current.contains(e.target)){
        setWidgetVisible(false);
      }}
    };

      document.addEventListener("mousedown", handler)
      return ()=>{
        document.removeEventListener("mousedown", handler)
      }
    });

  return (
    
    <div id = "header-menu-desktop" ref={menuRef}>
      <a
        id="header-menu-pricing"
        className={"primary header-menu-item"}
        href = "https://dealigense.com/user/login"
        target = "_blank"
        rel="noreferrer"
      >
      Client Login
      </a>
      <button 
      id="header-btn-contact-us"
      className="btn-primary header-menu-item"
      
      onClick={WidgetToggle}>Request a Demo</button>
      {widgetVisible && <Widget />}
    </div>
    
  )

}

export default class Header extends Component {
  
  render() {


    return (
      
      <div id="header-parent">
        <div id="header-container">
          <div id="header-branding">
            <a href = "https://dealigense.ai">
            <img
              id="header-branding-img"
              src="./images/dealigenseLogoFillOrange.svg"
              alt="Dealigense AI Marketing Platform"
            ></img>
            </a>
          </div>
          <div id="header-menu-desktop">
            {/* <a
              id="header-menu-pricing"
              className={"primary header-menu-item"}
              href = "https://dealigense.com/user/login"
              target = "_blank"
              
              >
              Client Login
              </a> */}
              
              
              {/* <button 
            id="header-btn-contact-us"
            className="btn-primary header-menu-item"
            >
              Request a Demo
              </button>    */}

        <WidgetToggle />
        </div>
        </div>
        <hr></hr>
      </div>
    );
  }
}


