// src/privacy.js
import React, { useState } from 'react';
import './Privacy.css';

function Privacy() {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  return (
    <div className="App">
      <h1>Privacy Statement</h1>
      <p>Effective Date: March 21, 2024</p>

      <Accordion
        title="Section 1: Introduction"
        isActive={activeSection === 1}
        onClick={() => toggleSection(1)}
      >
        <p>This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>
      </Accordion>

      <Accordion
        title="Section 2: Information We Collect"
        isActive={activeSection === 2}
        onClick={() => toggleSection(2)}
      >
        <p>We collect several different types of information for various purposes to provide and improve our Service to you. These include, but are not limited to, Personal Data (such as name, email address, phone number), Usage Data (such as page visits and service use), and Tracking & Cookies Data (used to track activity and hold certain information).</p>
      </Accordion>

      <Accordion
        title="Section 3: How We Use Your Information"
        isActive={activeSection === 3}
        onClick={() => toggleSection(3)}
      >
        <p>Your information helps us provide and improve our services, understand how you use our services, communicate with you about our services, and detect, prevent and address technical issues.</p>
      </Accordion>

      <Accordion
        title="Section 4: Sharing Your Information"
        isActive={activeSection === 4}
        onClick={() => toggleSection(4)}
      >
        <p>We may share your information with third parties in certain circumstances, including to comply with legal requirements, to protect the rights and safety of our company, our customers, and the public, or with service providers who help us provide our services.</p>
      </Accordion>

      <Accordion
        title="Section 5: Data Security"
        isActive={activeSection === 5}
        onClick={() => toggleSection(5)}
      >
        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
      </Accordion>

      {/* Add more sections as needed */}
    </div>
  );
}

function Accordion({ title, children, isActive, onClick }) {
  return (
    <div>
      <button className="accordion" onClick={onClick}>
        {title}
      </button>
      <div className={`panel ${isActive ? 'active' : ''}`}>
        {isActive && <div>{children}</div>}
      </div>
    </div>
  );
}

export default Privacy;
