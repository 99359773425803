import React from 'react';
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Header from "./components/header/Header";
import Pricing from "./components/pricing/Pricing";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import Terms from "./components/Terms/Terms"; // Import Terms page
import Privacy from "./components/Privacy/Privacy"; // Import Privacy page

function App() {
  return (
    <div className="app-container">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/terms" element={<Terms />} /> {/* Route for Terms of Conditions */}
          <Route path="/privacy" element={<Privacy />} /> {/* Route for Privacy Statement */}
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
