// src/components/terms/Terms.js
import React from 'react';
import './Terms.css'; // Import the CSS for styling

const Terms = () => {
    return (
        <div className="terms">
            <h1>Terms and Conditions</h1>
            <section>
                <h2>1. Introduction</h2>
                <p>Welcome to [Your Company Name]! These Terms and Conditions outline the rules and regulations for the use of [Your Company Name]'s Website, located at [Your Website URL].</p>
                <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use [Your Website] if you do not agree to take all of the terms and conditions stated on this page.</p>
            </section>
            <section>
                <h2>2. Intellectual Property Rights</h2>
                <p>Other than the content you own, under these Terms, [Your Company Name] and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
                <p>You are granted a limited license only for purposes of viewing the material contained on this Website.</p>
            </section>
            <section>
                <h2>3. Restrictions</h2>
                <p>You are specifically restricted from all of the following:</p>
                <ul>
                    <li>Publishing any Website material in any other media.</li>
                    <li>Selling, sublicensing and/or otherwise commercializing any Website material.</li>
                    <li>Publicly performing and/or showing any Website material.</li>
                    <li>Using this Website in any way that is or may be damaging to this Website.</li>
                    <li>Using this Website in any way that impacts user access to this Website.</li>
                    <li>Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity.</li>
                    <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website.</li>
                    <li>Using this Website to engage in any advertising or marketing without explicit permission.</li>
                </ul>
            </section>
            <section>
                <h2>4. Your Content</h2>
                <p>In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video, text, images, or other material you choose to display on this Website. By displaying Your Content, you grant [Your Company Name] a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.</p>
                <p>Your Content must be your own and must not be invading any third-party's rights. [Your Company Name] reserves the right to remove any of Your Content from this Website at any time, and for any reason, without notice.</p>
            </section>
            <section>
                <h2>5. No warranties</h2>
                <p>This Website is provided "as is," with all faults, and [Your Company Name] makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.</p>
            </section>
            <section>
                <h2>6. Limitation of liability</h2>
                <p>In no event shall [Your Company Name], nor any of its officers, directors, and employees, be liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract, tort, or otherwise, and [Your Company Name], including its officers, directors, and employees shall not be liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.</p>
            </section>
            <section>
                <h2>7. Indemnification</h2>
                <p>You hereby indemnify to the fullest extent [Your Company Name] from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
            </section>
            <section>
                <h2>8. Severability</h2>
                <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
            </section>
            <section>
                <h2>9. Variation of Terms</h2>
                <p>[Your Company Name] is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.</p>
            </section>
            <section>
                <h2>10. Assignment</h2>
                <p>[Your Company Name] is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
            </section>
            <section>
                <h2>11. Entire Agreement</h2>
                <p>These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between [Your Company Name] and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.</p>
            </section>
        </div>
    );
}

export default Terms;
